import React, { useEffect, useState } from "react";
import { fetcher } from "src/api/swr/request";
import useDeviceWidth from "src/hooks/useDeviceWidth";
import insertQuery from "src/views/v2/utils/insertQuery";
import useSWR from "swr";
const CategoryDropdown = ({ onSelect, value }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { data: expensecCategories, mutate: refecthCategories } = useSWR(
    insertQuery(`/expense-categories`, {}),
    fetcher
  );
  useEffect(() => {
    if (Object.keys(value).length > 0) {
      setSelectedCategory(value.expenseCategory);
      setSelectedSubCategory(value.expenseSubCategory);
      onSelect({
        mainCategoryId: value.expenseCategory?.id,
        subCategoryId: value.expenseSubCategory?.id,
      });
    }
  }, []);
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setSelectedSubCategory(null);
  };

  const handleSubCategorySelect = (subCategory) => {
    setSelectedSubCategory(subCategory);
  };

  const handleDoneClick = () => {
    setDropdownVisible(false);
    if (onSelect) {
      onSelect({
        mainCategoryId: selectedCategory?.id,
        subCategoryId: selectedSubCategory?.id,
      });
    }
  };

  const handleCancelClick = () => {
    setSelectedCategory(null);
    setSelectedSubCategory(null);
    onSelect({
      mainCategoryId: null,
      subCategoryId: null,
    });
    setDropdownVisible(false);
  };
  const deviceWidth = useDeviceWidth();
  const selectedCategoryName = ` ${selectedCategory?.name} - ${selectedSubCategory?.name}`;
  return (
    <div>
      <div style={{ position: "relative" }}>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => setDropdownVisible(!dropdownVisible)}
        >
          <input
            type="text"
            className="input"
            placeholder="Categories"
            readOnly
            value={
              selectedCategory && selectedSubCategory
                ? selectedCategoryName?.length > 12
                  ? `${selectedCategoryName.substring(0, 14)}...`
                  : selectedCategoryName
                : ""
            }
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "8px",
              border: "1px solid #ccc",
              cursor: "pointer",
            }}
          />
          <span style={{ position: "absolute", right: "10px", top: "13px" }}>
            <svg
              height="25"
              width="25"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
              class="css-tj5bde-Svg"
            >
              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
            </svg>
          </span>
        </div>

        {dropdownVisible && (
          <div
            style={{
              backgroundColor: "#FFFFFF",
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "16px",
              position: "absolute",
              left: `${deviceWidth >= 768 ? "-139px" : "-17px"}`,
              width: `${deviceWidth >= 768 ? "300px" : "120%"}`,
              zIndex: 3,
            }}
          >
            <h2
              style={{
                fontSize: "16px",
                marginBottom: `${deviceWidth >= 768 ? "10px" : "7px"}`,
                color: "black",
                fontWeight: "600",
              }}
            >
              Select Categories
            </h2>
            <div
              style={{
                maxHeight: `${deviceWidth >= 768 ? "250px" : "178px"}`,
                overflowY: "auto",
                scrollbarColor: "#6200ea #EAECF0",
                scrollBehavior: "smooth",
                scrollMarginLeft: "20px",
              }}
            >
              {expensecCategories?.map((category) => (
                <div
                  key={category.id}
                  style={{
                    marginBottom: "8px",
                    marginRight: "4px",
                  }}
                >
                  <label
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      padding: `${deviceWidth >= 768 ? "12px" : "6px"} 12px`,
                      borderRadius: "8px",
                      background: `${
                        selectedCategory?.id === category?.id ? "#f7f2ff" : ""
                      }`,
                    }}
                  >
                    <input
                      type="radio"
                      name="category"
                      // {...register(`${name}.${index}.category`)}
                      style={{ marginRight: "8px" }}
                      checked={selectedCategory?.id === category?.id}
                      onChange={() => handleCategorySelect(category)}
                    />
                    <span
                      style={{
                        flexGrow: 1,
                        color:
                          selectedCategory?.id === category?.id
                            ? "#6200ea"
                            : "#000",
                      }}
                    >
                      {category.name}
                    </span>
                    {selectedCategory?.id === category?.id ? (
                      // <ArrowDown style={{ color: "#6200ea" }} />
                      // <span style={{ color: "#6200ea", fontSize: "20px" }}>
                      //   &#8659;
                      // </span>
                      <span style={{ color: "blue" }}>
                        <svg
                          height="25"
                          width="25"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                          focusable="false"
                          class="css-tj5bde-Svg"
                          color="blue"
                        >
                          <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                        </svg>
                      </span>
                    ) : (
                      // <ArrowRight style={{ color: "#000" }} />
                      <svg
                        height="25"
                        width="25"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                        focusable="false"
                        class="css-tj5bde-Svg"
                        // color="#6200ea"
                      >
                        <path d="M7.548 4.516c-0.446 0.436-0.481 1.043 0 1.576l3.747 3.908-3.747 3.908c-0.481 0.533-0.446 1.141 0 1.574 0.445 0.436 1.197 0.408 1.615 0 0.418-0.406 4.502-4.695 4.502-4.695 0.223-0.217 0.335-0.502 0.335-0.787s-0.112-0.57-0.335-0.789c0 0-4.084-4.287-4.502-4.695s-1.17-0.436-1.615 0z"></path>
                      </svg>
                    )}
                  </label>
                  {selectedCategory?.id === category?.id && (
                    <div
                      style={{
                        // paddingLeft: "24px",
                        // marginTop: "2px",
                        borderRadius: "8px",
                        // padding: "10px 0px 10px 24px",
                        padding: `${
                          deviceWidth >= 768
                            ? "10px 0px 10px 24px"
                            : "7px 0px 7px 24px"
                        }`,
                        background: `${
                          selectedCategory?.id === category?.id ? "#f7f2ff" : ""
                        }`,
                        marginLeft: "18px",
                      }}
                    >
                      {category.expenseSubCategories.map(
                        (subCategory, index) => (
                          <label
                            key={subCategory?.id}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                              marginBottom: `${
                                index ==
                                category.expenseSubCategories.length - 1
                                  ? "0px"
                                  : "6px"
                              }`,
                            }}
                          >
                            <input
                              type="radio"
                              name="subCategory"
                              // {...register(`${name}.${index}.subCategory`)}
                              style={{ marginRight: "8px" }}
                              checked={
                                selectedSubCategory?.id === subCategory?.id
                              }
                              onChange={() =>
                                handleSubCategorySelect(subCategory)
                              }
                            />
                            <span
                              style={{
                                color:
                                  selectedSubCategory?.id === subCategory?.id
                                    ? "#6200ea"
                                    : "#000",
                              }}
                            >
                              {subCategory?.name}
                            </span>
                          </label>
                        )
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <button
                style={{
                  background: "none",
                  border: "1px solid #ccc",
                  padding: "8px 12px",
                  cursor: "pointer",
                  borderRadius: "4px",
                  color: "#6200ea",
                }}
                onClick={handleCancelClick}
              >
                Cancel
              </button>
              <button
                style={{
                  backgroundColor: "#6200ea",
                  color: "#fff",
                  padding: "8px 12px",
                  border: "none",
                  cursor: "pointer",
                  borderRadius: "4px",
                }}
                onClick={handleDoneClick}
                disabled={!selectedCategory || !selectedSubCategory}
              >
                Done
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryDropdown;
