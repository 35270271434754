import { CFade } from "@coreui/react";
import { ErrorMessage } from "@hookform/error-message";
import { joiResolver } from "@hookform/resolvers/joi";
import { Children, createElement, useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import toast from "react-hot-toast";
import { DatePicker } from "react-nice-dates";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { searchEmployee, uploadImage } from "src/api";
import AddIcon from "src/assets/icons/AddIcon";
import BinIcon from "src/assets/icons/BinIcon";
import Button from "src/views/components/reusable/Button";
import { HQColumn } from "../HQ/HQColumn";
import { CrossIcon } from "./../../../../assets/icons/CrossIcon";
import errorAlert from "./../alerts/ErrorAlert";

import DeleteIconNew from "src/assets/icons/DeleteIconNew";
import PlusIcon from "src/assets/icons/PlusIcon";
import PlusIconNew from "src/assets/icons/PlusIconNew";
import useDeviceWidth from "src/hooks/useDeviceWidth";
import CategoryDropdown from "./CategoryDropdown";
// Form Wrapper Component
export function HQForm({
  defaultValues,
  children,
  onSubmit,
  className = "my-2 my-md-4 form-container",
  schema,
  shouldUnregister,
}) {
  const options = {};
  if (defaultValues) {
    options.defaultValues = defaultValues;
  }
  if (schema) {
    options.resolver = joiResolver(schema);
  }
  if (shouldUnregister) {
    options.shouldUnregister = shouldUnregister;
  }
  const methods = useForm(options);

  const { handleSubmit, reset, formState } = methods;

  // console.log(formState.errors);

  // Reset form with fetched default values
  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, reset]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className={className} noValidate>
        {Children?.map(children, (child) => {
          return child && child.props.name
            ? createElement(child.type, {
                ...{
                  ...child.props,
                  key: child.props.name,
                },
              })
            : child;
        })}
      </form>
    </FormProvider>
  );
}

// Input Component
export function HQInput({
  label,
  name,
  className = "input-container",
  validation = {},
  required,
  ...rest
}) {
  const { register, formState } = useFormContext();
  // Usage: <HQInput label="Label" name="name"  required validation={required:"This is required"}/>
  return (
    <div className={className}>
      {label && <HQInputLabel name={name} label={label} required={required} />}
      <input
        id={name}
        className="input"
        {...register(name, validation)}
        {...rest}
      />
      {/* Show Error Message */}
      <ErrorMessage
        errors={formState?.errors}
        name={name}
        render={({ message }) => (
          <CFade className="mb-2 d-flex align-items-center">
            <CrossIcon />
            <p className="ml-1 input-error">{message}</p>
          </CFade>
        )}
      />
    </div>
  );
}

export function HQTextArea({
  onChange = () => {},
  onFocus = () => {},
  label,
  name,
  validation = {},
  required,
  children,
  rows = "6",
  className = "input-container",
  style = {},
  ...rest
}) {
  const { register, formState } = useFormContext();

  return (
    <div className={className}>
      {label && <HQInputLabel name={name} label={label} required={required} />}
      <textarea
        id={name}
        className="text-area"
        rows={rows}
        style={style}
        {...register(name, validation)}
        {...rest}
        onFocus={onFocus}
      >
        {children}
      </textarea>
      {/* Show Error Message */}
      <ErrorMessage
        errors={formState?.errors}
        name={name}
        render={({ message }) => (
          <CFade className="mb-2 d-flex align-items-center">
            <CrossIcon />
            <p className="ml-1 input-error">{message}</p>
          </CFade>
        )}
      />
    </div>
  );
}

// Button Component
// TODO: Need more
export function HQButton({ type, children, icon, className, Icon, ...rest }) {
  // Usage: <HQButton> Submit </HQButton>
  const {
    formState: { isDirty, errors, isSubmitting },
  } = useFormContext();
  return (
    <>
      <Button
        size="medium"
        Icon={Icon}
        className={className}
        disabled={isSubmitting || !isDirty}
        type={type}
      >
        {children}
      </Button>
    </>
  );
}

// Input Label
export function HQInputLabel({ name, label, required, className }) {
  return (
    <label htmlFor={name} className={!className ? "input-label" : className}>
      {label} {required && <span style={{ color: "#FF0000" }}>*</span>}
    </label>
  );
}

// Updated Select Using react-select
export function HQSelect({
  options,
  name,
  label,
  required,
  readOnly,
  isSearchable = true,
  validation,
  defaultValues,
  ...rest
}) {
  const { formState, control } = useFormContext();
  return (
    <div class="input-container">
      {label && <HQInputLabel name={name} label={label} required={required} />}
      {/* Controller for controlled select compoent */}
      <Controller
        name={name}
        control={control}
        rules={validation}
        {...rest}
        render={({ field }) => (
          <>
            {/* Conditionally render disabled field */}
            {readOnly ? (
              <Select
                {...field}
                isDisabled
                className="select-container"
                classNamePrefix="select"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#faf7ff",
                    primary: "#6808FF",
                  },
                })}
                options={options}
              />
            ) : (
              <Select
                {...field}
                className="select-container"
                classNamePrefix="select"
                isSearchable={isSearchable}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#faf7ff",
                    primary: "#6808FF",
                  },
                })}
                options={options}
              />
            )}
          </>
        )}
      />
      {/* Show Error Message */}
      <ErrorMessage
        errors={formState?.errors}
        name={name}
        render={({ message }) => (
          <CFade className="my-1 d-flex align-items-center">
            <CrossIcon />
            <p className="ml-1 input-error">{message}</p>
          </CFade>
        )}
      />
    </div>
  );
}

// Input
export function HQFileInput({ name, label, required, onChange, ...rest }) {
  const { formState } = useFormContext();
  return (
    <div class="input-container">
      {label && (
        <label htmlFor={name} className="input-label">
          {label} {required && <span style={{ color: "#FF0000" }}>*</span>}
        </label>
      )}
      <input
        type="file"
        id={name}
        name={name}
        className="input"
        onChange={onChange}
        {...rest}
      />
      {/* Show Error Message */}
      <ErrorMessage
        errors={formState?.errors}
        name={name}
        render={({ message }) => (
          <CFade className="my-1 d-flex align-items-center">
            <CrossIcon />
            <p className="ml-1 input-error">{message}</p>
          </CFade>
        )}
      />
    </div>
  );
}
export function HQImageUpload({ name, label, required, ...rest }) {
  const { formState, setValue } = useFormContext();
  const onChangeHandler = async (e) => {
    // Check file size
    if (e.target.files?.[0]?.size > 5000000) {
      e.target.value = "";
      return errorAlert("File size is too large");
    }

    const imagesData = new FormData();
    imagesData.append("image", e.target.files[0]);

    const toastId = toast.loading("Uploading Image...");
    try {
      const { data } = await uploadImage(imagesData);

      if (data.url) {
        setValue(name, data.url, { shouldValidate: true, shouldDirty: true });
        toast.success("Image Uploaded Successfully", { id: toastId });
        return;
      }
    } catch (error) {
      setValue(name, null, { shouldValidate: true });
      toast.error(error?.response?.data?.message, { id: toastId });
      return;
    }
  };
  return (
    <div class="input-container">
      {label && (
        <label htmlFor={name} className="input-label">
          {label} {required && <span style={{ color: "#FF0000" }}>*</span>}
        </label>
      )}
      <input
        type="file"
        id={name}
        name={name}
        className="input"
        onChange={onChangeHandler}
        {...rest}
      />
      {/* Show Error Message */}
      <ErrorMessage
        errors={formState?.errors}
        name={name}
        render={({ message }) => (
          <CFade className="my-1 d-flex align-items-center">
            <CrossIcon />
            <p className="ml-1 input-error">{message}</p>
          </CFade>
        )}
      />
    </div>
  );
}

// HQDate
export function HQDateInput({
  name,
  label,
  required,
  validation,
  minimumDate,
  ...rest
}) {
  const { control, formState, getValues } = useFormContext();
  const [date, setDate] = useState();

  // Get the defaultValue that is set during reset()
  const temp = getValues(name);
  useEffect(() => {
    if (temp) {
      // update state to show the set in reset
      setDate(new Date(temp));
    }
  }, [temp]);

  return (
    <div className="input-container">
      {label && (
        <label htmlFor={name} className="input-label">
          {label} {required && <span style={{ color: "#FF0000" }}>*</span>}
        </label>
      )}

      <Controller
        name={name}
        control={control}
        rules={validation}
        {...rest}
        render={({ field: { onChange } }) => (
          <DatePicker
            date={date}
            format="dd-MM-yyyy"
            minimumDate={minimumDate}
            onDateChange={(date) => {
              onChange(date);
              setDate(date);
            }}
          >
            {({ inputProps }) => <input className="input" {...inputProps} />}
          </DatePicker>
        )}
      />
      {/* Show Error Message */}
      <ErrorMessage
        errors={formState?.errors}
        name={name}
        render={({ message }) => (
          <CFade className="my-1 d-flex align-items-center">
            <CrossIcon />
            <p className="ml-1 input-error">{message}</p>
          </CFade>
        )}
      />
    </div>
  );
}

// useArrayField - Expando Form
// Not reusable
// Dependecy - AddExpense.js

export function HQAddFieldForExpense({
  label,
  name,
  validation = {},
  required,
  ...rest
}) {
  const { formState, register } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name,
  });

  return (
    <div>
      {label && <HQInputLabel name={name} label={label} required={required} />}
      {fields.map((item, index) => {
        return (
          <div key={item.id} className="row">
            <div className="col-md-6">
              <div className="input-container">
                {<HQInputLabel name="purpose" label="Purpose" />}
                <input
                  className="input"
                  {...register(`${name}.${index}.purpose`, validation)}
                />
              </div>
              <ErrorMessage
                errors={formState?.errors[name]?.[index]}
                name="purpose"
                render={({ message }) => (
                  <CFade className="mb-2 d-flex align-items-center">
                    <CrossIcon />
                    <p className="ml-1 input-error">{message}</p>
                  </CFade>
                )}
              />
            </div>
            <div className="col-md-6 margin-left-on-child">
              <div className="input-container">
                {<HQInputLabel name="amount" label="Amount" />}
                <input
                  className="input"
                  type="number"
                  {...register(`${name}.${index}.amount`, {
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "This input is number only.",
                    },
                    required: "This field cannot be empty",
                  })}
                />
                <ErrorMessage
                  errors={formState?.errors[name]?.[index]}
                  name="amount"
                  render={({ message }) => (
                    <CFade className="mb-2 d-flex align-items-center">
                      <CrossIcon />
                      <p className="ml-1 input-error">{message}</p>
                    </CFade>
                  )}
                />
                {/* <button className="col-md-2" onClick={() => remove(index)}>
                  Delete
                </button> */}
              </div>
            </div>
          </div>
        );
      })}
      <Button
        className="mt-2 ml-auto button-bg"
        type="button"
        variant="outline"
        Icon={AddIcon}
        onClick={() => {
          append({});
        }}
      >
        Add More
      </Button>
    </div>
  );
}

export function HQAddField({
  label,
  name,
  register,
  control,
  formState,
  validation = {},
  totalAmount = 0,
  required,
  setValue,
  ...rest
}) {
  // const { formState, register } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });
  const deviceWidth = useDeviceWidth();
  return (
    <div>
      {label && <HQInputLabel name={name} label={label} required={required} />}
      {fields.map((item, index) => {
        return (
          <div
            key={item.id}
            // className="row justify-content-between align-items-center"
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
              // gap: `${deviceWidth < 768 ? "8px" : "10px"}`,
            }}
          >
            <div
              style={{
                width: "97%",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: `${deviceWidth < 768 ? "column" : "row"}`,
                gap: `${deviceWidth < 768 ? "0px" : "6px"}`,
                marginTop: `${deviceWidth < 768 ? "20px" : "0px"}`,
              }}
            >
              <div
                className=""
                style={{ width: `${deviceWidth < 768 ? "100%" : "85%"}` }}
              >
                <div className="input-container">
                  {/* {<HQInputLabel name="purpose" label="Purpose" />} */}
                  <input
                    className="input"
                    placeholder="Enter your expense subject"
                    {...register(`${name}.${index}.purpose`, validation)}
                  />
                </div>
                {/* <div>
                <ErrorMessage
                  errors={formState?.errors[name]?.[index]}
                  name="purpose"
                  render={({ message }) => (
                    <CFade className="mb-2 d-flex align-items-center">
                      <CrossIcon />
                      <p className="ml-1 input-error">{message}</p>
                    </CFade>
                  )}
                />
              </div> */}
              </div>
              <div
                className=""
                style={{ width: `${deviceWidth < 768 ? "100%" : "21%"}` }}
              >
                <div
                  className=""
                  // style={{
                  //   display: "flex",
                  //   flexDirection: "column",
                  //   gap: "5px",
                  //   justifyContent: "",
                  // }}
                >
                  {/* {<HQInputLabel name="amount" label="Amount" />} */}
                  <input
                    className="input"
                    type="number"
                    placeholder="amount"
                    // min={1}
                    {...register(`${name}.${index}.amount`, {
                      // valueAsNumber: true,
                      // required: "This field cannot be empty",
                      // min: {
                      //   value: 1,
                      //   message: "It  must be grater than 0.",
                      // },
                    })}
                  />
                  {/* <div style={{ position: "absolute", top: "28px", right: "0" }}>
                  <ErrorMessage
                    errors={formState?.errors[name]?.[index]}
                    name="amount"
                    render={({ message }) => (
                      <CFade className="mb-2 d-flex align-items-center mt-4">
                        <CrossIcon />
                        <p
                          className="ml-1 input-error"
                          style={{ margin: "0px" }}
                        >
                          {message}
                        </p>
                      </CFade>
                    )}
                  />
                </div> */}
                </div>
              </div>
              <div
                className=""
                style={{
                  width: `${deviceWidth < 768 ? "100%" : "25%"}`,
                }}
              >
                <CategoryDropdown
                  value={{
                    expenseCategory: item?.expenseCategory,
                    expenseSubCategory: item?.expenseSubCategory,
                  }}
                  onSelect={({ mainCategoryId, subCategoryId }) => {
                    setValue(
                      `${name}.${index}.expenseCategoryId`,
                      mainCategoryId
                    );
                    setValue(
                      `${name}.${index}.expenseSubCategoryId`,
                      subCategoryId
                    );
                  }}
                />
              </div>
            </div>

            <div
              className=""
              style={{ marginTop: `${deviceWidth < 768 ? "0" : "-12px"}` }}
            >
              <DeleteIconNew onClick={() => remove(index)} />
            </div>
          </div>
        );
      })}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            width: "100px",
            height: "40px",
            // border: "2px solid #6808FF",
            // borderRadius: "8px",
            color: "#6808FF",
            fontSize: "16px",
            fontWeight: "500",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => {
            append({});
          }}
        >
          <p
            style={{
              fontWeight: "500",
              // marginTop: "14px",
              fontSize: `${deviceWidth < 768 ? "14px" : "16px"}`,
              color: "#6808FF",
            }}
          >
            {" "}
            <PlusIconNew color="#6808FF" />
            Add Item
          </p>
        </div>
        {fields?.length > 0 && (
          <div>
            <p
              style={{
                fontWeight: "600",
                // marginTop: "14px",
                fontSize: `${deviceWidth < 768 ? "14px" : "16px"}`,

                color: "#00AB5E",
              }}
            >
              {deviceWidth >= 768 && "Grand"}Total = {totalAmount}{" "}
              {/* {deviceWidth >= 768 && "Taka"} */}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
export function HQAddLoanField({
  label,
  name,
  validation = {},
  required,
  fields,
  append,
  remove,
  ...rest
}) {
  const { formState, register } = useFormContext();

  return (
    <div>
      {label && <HQInputLabel name={name} label={label} required={required} />}
      {fields.map((item, index) => {
        return (
          <div key={item.id} className="d-flex align-items-center">
            <div className="px-2">
              {<HQInputLabel name="minAmount" label="Min. Loan Amount" />}
              <input
                className="input"
                {...register(`${name}.${index}.minAmount`, {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "This input is number only.",
                  },
                  required: "This field cannot be empty",
                })}
              />
              <ErrorMessage
                errors={formState?.errors[name]?.[index]}
                name="minLoanAmount"
                render={({ message }) => (
                  <CFade className="mb-2 d-flex align-items-center">
                    <CrossIcon />
                    <p className="ml-1 input-error">{message}</p>
                  </CFade>
                )}
              />
            </div>
            <div className="px-2">
              {<HQInputLabel name="maxAmount" label="Max. Loan Amount" />}
              <input
                className="input"
                {...register(`${name}.${index}.maxAmount`, {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "This input is number only.",
                  },
                  required: "This field cannot be empty",
                })}
              />
              <ErrorMessage
                errors={formState?.errors[name]?.[index]}
                name="maxInstallmentsCount"
                render={({ message }) => (
                  <CFade className="mb-2 d-flex align-items-center">
                    <CrossIcon />
                    <p className="ml-1 input-error">{message}</p>
                  </CFade>
                )}
              />
            </div>
            <div className="px-2">
              {
                <HQInputLabel
                  name="maxInstallmentsCount"
                  label="Max Installment Count"
                />
              }
              <input
                className="input"
                {...register(`${name}.${index}.maxInstallmentsCount`, {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "This input is number only.",
                  },
                  required: "This field cannot be empty",
                })}
              />
              <ErrorMessage
                errors={formState?.errors[name]?.[index]}
                name="maxInstallmentsCount"
                render={({ message }) => (
                  <CFade className="mb-2 d-flex align-items-center">
                    <CrossIcon />
                    <p className="ml-1 input-error">{message}</p>
                  </CFade>
                )}
              />
            </div>
            <div className="pl-2">
              {
                <HQInputLabel
                  name="maxSkipCount"
                  label="Max. Installment Skip"
                />
              }
              <input
                className="input"
                {...register(`${name}.${index}.maxSkipCount`, {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "This input is number only.",
                  },
                  required: "This field cannot be empty",
                })}
              />
              <ErrorMessage
                errors={formState?.errors[name]?.[index]}
                name="maxSkipCount"
                render={({ message }) => (
                  <CFade className="mb-2 d-flex align-items-center">
                    <CrossIcon />
                    <p className="ml-1 input-error">{message}</p>
                  </CFade>
                )}
              />
            </div>
            <button
              className="mt-3 ml-2 button-ghost-primary"
              onClick={() => remove(index)}
            >
              <BinIcon size="35" />
            </button>
          </div>
        );
      })}
      <button
        className="px-3 my-4 ml-auto mr-5 button-bg d-flex align-items-center"
        type="button"
        onClick={() => {
          append({});
        }}
      >
        <AddIcon margin="0 10px 0 0" />
        Add Field
      </button>
    </div>
  );
}

//! Not reuseabl

export function HQAddBudgetField({
  label,
  name,
  validation = {},
  required,
  ...rest
}) {
  const { formState, register } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name,
  });

  return (
    <div className="w-100">
      {label && <HQInputLabel name={name} label={label} required={required} />}
      {fields.map((item, index) => {
        return (
          <div key={item.id} className="d-flex">
            <HQColumn>
              <div className="input-container">
                {<HQInputLabel name="costName" label="Cost Name" />}
                <input
                  className="input"
                  {...register(`${name}.${index}.costName`, validation)}
                />
                <ErrorMessage
                  errors={formState?.errors[name]?.[index]}
                  name="costName"
                  render={({ message }) => (
                    <CFade className="mb-2 d-flex align-items-center">
                      <CrossIcon />
                      <p className="ml-1 input-error">{message}</p>
                    </CFade>
                  )}
                />
              </div>
            </HQColumn>
            <HQColumn isRight={true}>
              <div className="input-container">
                {<HQInputLabel name="costAmount" label="Cost Amount" />}
                <input
                  className="input"
                  {...register(`${name}.${index}.costAmount`, {
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "This input is number only.",
                    },
                    required: "This field cannot be empty",
                  })}
                />
                <ErrorMessage
                  errors={formState?.errors[name]?.[index]}
                  name="costAmount"
                  render={({ message }) => (
                    <CFade className="mb-2 d-flex align-items-center">
                      <CrossIcon />
                      <p className="ml-1 input-error">{message}</p>
                    </CFade>
                  )}
                />
                {/* <button className="button-bg" onClick={() => remove(index)}>
                Delete
              </button> */}
              </div>
            </HQColumn>
          </div>
        );
      })}
      <div className="d-flex">
        <button
          className="ml-auto button-md-outline"
          type="button"
          onClick={() => {
            append({});
          }}
        >
          Expense Details
        </button>
        <button className="mx-3 button-md" type="submit">
          Submit
        </button>
      </div>
    </div>
  );
}

// Debounce function call
async function debounce(func, wait, immediate) {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;

    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}
export const HQAsyncMultiSelect = ({ name, label, required }) => {
  // TODO: DEBOUNCE SEARCH
  const { control, formState } = useFormContext();

  const loadOptions = (inputValue) =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        const res = searchEmployee(inputValue);
        res
          .then(({ data }) => {
            if (data.status) {
              return resolve(
                data.data.map((item) => ({
                  label: item.fullName,
                  value: item.uuid,
                }))
              );
            } else {
              return resolve([]);
            }
          })
          .catch((err) => {});
      }, 1000);
    });
  const loadData = (inputValue) => {
    const res = searchEmployee(inputValue);
    res
      .then(({ data }) => {
        if (data.status) {
          return data.data.map((item) => ({
            label: item.fullName,
            value: item.uuid,
          }));
        } else {
          return [];
        }
      })
      .catch((err) => {});
  };
  // const debouncedLoadOptions = debounce(loadData, 500); // ! Not working

  return (
    <div class="input-container">
      {label && <HQInputLabel name={name} label={label} required={required} />}
      {/* Controller for controlled select compoent */}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <AsyncSelect
              {...field}
              className="multi-select-container"
              classNamePrefix="multi-select"
              isMulti
              cacheOptions
              loadOptions={loadOptions}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#faf7ff",
                  primary: "#6808FF",
                },
              })}
            />
          </>
        )}
      />
      {/* Show Error Message */}
      <ErrorMessage
        errors={formState?.errors}
        name={name}
        render={({ message }) => (
          <CFade className="my-1 d-flex align-items-center">
            <CrossIcon />
            <p className="ml-1 input-error">{message}</p>
          </CFade>
        )}
      />
    </div>
  );
};

export const HQMultiSelect = ({ name, label, required, options = {} }) => {
  const { control, formState } = useFormContext();
  return (
    <div class="input-container">
      {label && <HQInputLabel name={name} label={label} required={required} />}
      {/* Controller for controlled select compoent */}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <Select
              {...field}
              isMulti
              className="multi-select-container"
              classNamePrefix="multi-select"
              options={options}
              isSearchable={true}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#faf7ff",
                  primary: "#6808FF",
                },
              })}
            />
          </>
        )}
      />
      {/* Show Error Message */}
      <ErrorMessage
        errors={formState?.errors}
        name={name}
        render={({ message }) => (
          <CFade className="my-1 d-flex align-items-center">
            <CrossIcon />
            <p className="ml-1 input-error">{message}</p>
          </CFade>
        )}
      />
    </div>
  );
};

export const HQCheckbox = ({ name, label, required, ...rest }) => {
  const { formState, register } = useFormContext();
  return (
    <div class="input-container text-gray-4 my-4">
      <div className="d-flex align-items-center">
        <input
          id={name}
          className="mr-2"
          type="checkbox"
          {...register(name)}
          {...rest}
        />
        {label && (
          <HQInputLabel
            className="mb-0"
            name={name}
            label={label}
            required={required}
          />
        )}
      </div>

      {/* Show Error Message */}
      <ErrorMessage
        errors={formState?.errors}
        name={name}
        render={({ message }) => (
          <CFade className="my-1 d-flex align-items-center">
            <CrossIcon />
            <p className="ml-1 input-error">{message}</p>
          </CFade>
        )}
      />
    </div>
  );
};

// HQ Time Picker Component
export const HQTimePicker = ({ name, label, required, ...rest }) => {
  const { formState, register } = useFormContext();
  return (
    <div class="input-container">
      {label && <HQInputLabel name={name} label={label} required={required} />}
      <input className="input" type="time" {...register(name)} {...rest} />
      {/* Show Error Message */}
      <ErrorMessage
        errors={formState?.errors}
        name={name}
        render={({ message }) => (
          <CFade className="my-1 d-flex align-items-center">
            <CrossIcon />
            <p className="ml-1 input-error">{message}</p>
          </CFade>
        )}
      />
    </div>
  );
};
